<template>
  <div class="with-anchors image-space">
    <h1 class="display-l">Photography</h1>
    <p>Our imagery should always have an element of both or either light and movement to create a visual connection to the themes of technology and movement.
To keep photography feeling consistent, energised, and with a touch of humanity running throughout there should always be a warm grade running thought and blues in the shadows as seen in the examples on the right.</p>
    
    <!-- styling -->
    <section>
        <h2 id="styling" class="display-m">Styling</h2>
        <p class="page-heading">Cropping photography</p>
        <p>There are various ways that photography can be cropped to ensure it feels consistent with the overall visual identity system, these have been outlined below:</p><br/>
        <p><b>1. Subtle radius corners</b></p>
        <p>When using an isolated image ensure the corners have a subtle radius applied to ensure they feel connected to our look and feel. The radius should feel subtle and refined, never be large and childish.</p>
        <p><img src="../../assets/img/photo1.png" alt="" role="presentation"/></p><br/>

        <p><b>2. Inside a circle</b></p>
        Images can be cropped inside a circle, you may also choose to apply the ‘Intele treatment’ or layer with another circle to resemble the ‘Highbeam treatment’.
        <p><img src="../../assets/img/photo2.png" alt="" role="presentation" /></p><br/>

        <p><b>3. Inside ‘The Contact Stamp’</b></p>
        <p>See <a href="/foundations/graphic-treatments">Graphic treatments</a></p>
        <p><img src="../../assets/img/photo3.png" alt="" role="presentation" /></p><br/>

        <p><b>4. Full bleed</b></p>
        <p>Images may also go full bleed and have other elements layered over them e.g. ‘The Contact Stamp’</p>
        <p><img src="../../assets/img/photo4.png" alt="" role="presentation" /></p>
    </section>

    <!-- treatment -->
    <section >
        <h2 id="treatment" class="display-m">Treatment</h2>
        <p class="section-heading">InteleTreatment</p>
        <p>Used to energise and brand images the ‘InteleTreatment’ can be either layered through imagery or positioned above. Appearing predominantly in Cornflower and Mint to build brand recognition. However, in secondary pages and internal communications we can colour this treatment with the tertiary palette.</p>
        <p><img src="../../assets/img/photo5.png" alt="" role="presentation" /></p><br/>

        <div class="alert">Please note, we never stack the ‘InteleTreatment’ more than four times to avoid over complicating visuals.</div>
        <p><img src="../../assets/img/photo6.png" alt="" role="presentation" /></p><br/>

        <p class="section-heading">Spotlight Treatment</p>
        <p>Used to highlight specific content and brand images. Appearing predominantly in Cornflower and Mint to build brand recognition. However, in secondary pages and internal communications we can colour this treatment with the tertiary palette.</p>
        <p><img src="../../assets/img/photo7.png" alt="" role="presentation" /></p><br/>
        
    </section>

    <DownloadCTA title="Download photography suite " 
                description="Files available in .jpg format" 
                buttonTitle="Download .zip"
                link="/zip/Intelematics-photography.zip" />
                
    <AnchorMenu :anchors="photoMenu"/>

  </div>
</template>

<script>
import AnchorMenu from "../common/AnchorMenu";
import DownloadCTA from "../common/DownloadCTA"
import { photoAnchors } from '../../assets/data/anchors'

export default {
  name: 'Photography',
  components: {
    AnchorMenu,
    DownloadCTA
  },
  data: () => {
    return {
      photoMenu: photoAnchors,
    };
  },
}
</script>
